/* Hide Volunteer and Get Help header nav items */
.upper-nav { visibility: hidden !important; }

.banner {
  background-image: url('/img/background_banner.jpg');
  height: 438px;
  padding: 20px;
}
.header_box {
  background-color: rgba(226, 135, 4, 0.8);
  opacity: 0.8;
  color: #ffffff;
  width: 400px;
  margin: 20px;
  margin-left: 10%;
  padding: 20px;
  min-height: 200px;
}
.box_title { color: #ffffff; text-shadow: 0px 2px #7B7B7B; }
.main_content {
  margin: auto;
  width: 80%;
  margin-top: -410px;
  padding: 35px 25px 0px 10px;
}
.start { margin-bottom: 30px; }
.partnering { margin-top: 60px; }
.app_icons { margin-top: 30px; }

.box-shadow-outer {
  margin: 0 auto;
  position: relative;
  width: 90%;
  height: 400px;
  background: linear-gradient(to bottom, rgba(0,0,0,0.30) 0%,rgba(0,0,0,0) 95%,rgba(0,0,0,0) 100%);
  margin-top: -75px;
  z-index: 0;
}
.box-shadow-inner {
  background-color: #ffffff;
  position: absolute;
  width: 98%;
  height: 400px;
  top: -15px; left: 1%;
  z-index: 0;
}

.app-developers {
  padding-bottom: 200px;
  background: linear-gradient(to top, rgba(206,206,206,0.30) 0%,rgba(0,0,0,0) 35%,rgba(0,0,0,0) 100%);
}